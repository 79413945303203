.session-list {
  transition: 0.2s ease-in-out;
  background-color: rgb(195, 205, 207);
  font-size: 16px;
  border-radius: 30px !important;
}

.session-list {
  &.selected {
    background-color: #414bb2;
    color: white;
    transform: scale(1.1);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    background-color: rgb(195, 205, 207);
    color: #696969;
    cursor: not-allowed;
  }
 }
